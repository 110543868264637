<template>
  <section class="official--partner-section-desc mt-5">
    <h2 class="subtitle--text">{{ $t('officialPartner.detail') }}</h2>
    <hr />
    <div class="detail--property-wrapper mb-5 row">
      <div class="col-6 col-md-3 mb-4" v-if="type">
        <h3 class="mb-0 title--text">
          <b>{{ $t('addListing.propDetail') }}</b>
        </h3>
        <div class="detail--text">{{ type }}</div>
      </div>
      <div class="col-6 col-md-3 mb-4" v-if="doneYearEst">
        <h3 class="mb-0 title--text">
          <b>{{ $t('officialPartner.doneYearEst') }}</b>
        </h3>
        <div class="detail--text">{{ doneYearEst }}</div>
      </div>
      <div class="col-6 col-md-3 mb-4" v-if="minPrice">
        <h3 class="mb-0 title--text">
          <b>{{ $t('general.filter.price_asc') }}</b>
        </h3>
        <div class="detail--text">{{ minPrice }}</div>
      </div>
      <div class="col-6 col-md-3 mb-4" v-if="maxPrice">
        <h3 class="mb-0 title--text">
          <b>{{ $t('general.filter.price_dsc') }}</b>
        </h3>
        <div class="detail--text">{{ maxPrice }}</div>
      </div>
      <div class="col-6 col-md-3 mb-4" v-if="listingType === 'R' && rentPeriod">
        <h3 class="mb-0 title--text">
          <b>{{ $t('general.rentPeriod') }}</b>
        </h3>
        <div class="detail--text">{{ `Per ${rentPeriod}` }}</div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    doneYearEst: {
      type: String,
    },
    minPrice: {
      type: String,
    },
    maxPrice: {
      type: String,
    },
    rentPeriod: {
      type: String,
    },
    type: {
      type: String,
    },
    listingType: {
      type: String,
    },
  },
};
</script>
